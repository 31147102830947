


































import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import BoxLicense from '#/components/BoxLicense.vue'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { UserStore, ILodashSetParams } from '#/store/user'
import { ModalStore, ModalType, IModalParams } from '~/store/modal'
import { fetchLicenseOptions, removeLicenceOption, createLicenseOption, updateLicenseOption } from '#/api/license-option'
import { updateProducer } from '#/api/profile'
import { UploadType, UploadStore } from '~/store/upload'

@Component({ components: { VLoadSpinner, BoxLicense } })
export default class LicensesPage extends Vue {
    isLoading = true
    licenseOptions: Array<Partial<ILicenseOptionProducer>> = []

    @UserStore.State('authenticatedUser') authenticatedUser: IUserProducer

    get licenseSummary() {
        return this.authenticatedUser ? this.authenticatedUser.license_summary_pdf : null
    }

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
    @UserStore.Mutation('UPDATE_PRODUCER_PROPERTY_LOCALLY') UPDATE_PRODUCER_PROPERTY_LOCALLY: (ILodashSetParams: ILodashSetParams) => void
    @UploadStore.Action('singleFileUpload') singleFileUpload: (IClientUploadParams: IClientUploadParams) => Promise<IUploadReturn>

    async updateLicensePdf(fileList: FileList) {
        try {
            const { url } = await this.singleFileUpload({ fileList, uploadType: UploadType.Public })
            await updateProducer({ id: this.authenticatedUser.id, license_summary_pdf: url })
            // set it on client for visual feedback
            this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'license_summary_pdf', value: url })
            this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
        } catch (err) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    async removeLicenseSummaryPdf() {
        await updateProducer({ id: this.authenticatedUser.id, license_summary_pdf: null })
        this.UPDATE_PRODUCER_PROPERTY_LOCALLY({ propertyPath: 'license_summary_pdf', value: null })
    }

    addBlankCoupon() {
        const defaultLicenseOption: Partial<ILicenseOptionProducer> = {
            name: '',
            is_exclusive: false,
            allow_offer_for_exclusivity: false,
            price: undefined,
            sale_units: undefined,
            show_streams: false,
            stream_units: 0,
            mp3: true,
            wav: false,
            trackout: false,
            intro_tag: false,
            is_royalty_free: true,
            is_featured: false,
            deal_buy: 0,
            deal_get: 0,
            isNew: true
        }
        this.licenseOptions.push(defaultLicenseOption)
    }

    async fetchLicenseOptions() {
        this.isLoading = true
        const licenseOptionsResponse = await fetchLicenseOptions()
        licenseOptionsResponse.map(coupon => (coupon.isNew = false)).sort((a: any, b: any) => (a.value !== b.value ? a.value - b.value : a.price - b.price))
        this.licenseOptions = licenseOptionsResponse
        this.isLoading = false
    }

    setUpdatedPropertyOnLicenseOption(licenseOption: ILicenseOptionProducer, index: number) {
        this.licenseOptions[index] = licenseOption
        if (!licenseOption.isNew) this.licenseOptions[index].isUpdated = true
    }

    async saveLicenseOptions() {
        Promise.all(
            this.licenseOptions.map(async licenseOption => {
                // if sale unites are empty, set to 0 (unlimited)
                if (!licenseOption.sale_units) licenseOption.sale_units = 0
                // always add the file type below the one selected by user, in case he forgets
                if (licenseOption.wav) licenseOption.mp3 = true
                if (licenseOption.trackout) {
                    licenseOption.mp3 = true
                    licenseOption.wav = true
                }
                // if it is not a valid number, don't pass it to the backend
                if (!licenseOption.price) licenseOption.price = null
                // api calls
                try {
                    if (licenseOption.isNew) await createLicenseOption(licenseOption)
                    else if (!licenseOption.isNew && licenseOption.isUpdated) await updateLicenseOption(licenseOption)
                    this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
                } catch (err) {
                    this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
                    // add error to display to the user
                    if (!licenseOption.name) licenseOption.error = 'You must enter a license name'
                    if (!licenseOption.is_exclusive && !licenseOption.price) licenseOption.error = 'You must enter the price'
                }
            })
        )
    }

    async removeLicenseOption(licenseOptionIndex: number) {
        try {
            const licenseOption = this.licenseOptions[licenseOptionIndex]
            if (!licenseOption.isNew) await removeLicenceOption(licenseOption.id)
            // remove locally
            this.licenseOptions.splice(licenseOptionIndex, 1)
            // show success message
            this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
            // re-fetch License Options
            this.fetchLicenseOptions()
        } catch (err) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    openConfirmActionModal(licenseOptionIndex: number) {
        this.SET_ACTIVE_MODAL({ modal: ModalType.ConfirmAction, callback: () => this.removeLicenseOption(licenseOptionIndex) })
    }

    mounted() {
        this.fetchLicenseOptions()
    }
}
