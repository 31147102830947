




























































































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'

@Component
export default class BoxLicense extends Vue {
	@Prop({ type: Object }) licenseOption!: ILicenseOptionProducer
	@Prop({ type: Number }) index!: number

	removeLicenseOption() {
		this.$emit('callRemoveLicenseOption', this.index)
	}

	@Watch('licenseOption', { deep: true })
	onLicenseChange() {
		if (this.licenseOption.is_exclusive) {
			this.licenseOption.wav = true
			this.licenseOption.trackout = true
		}
		this.$emit('callUpdateLicenseOption', this.licenseOption, this.index)
	}
}
